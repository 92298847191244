import React,{useState} from 'react'
import { Container,Form,Col,Button } from 'react-bootstrap'
import {useNavigate } from 'react-router-dom';
import { loginDetailSave } from '../Redux/Action/UserDetail';
import { useDispatch } from 'react-redux';
import { emptyProperty, houseDataEmpty } from '../Redux/Action/PropertyAction';
import { emptyTodayLogs } from '../Redux/Action/LogsAction';
import { emptySchedule } from '../Redux/Action/ScheduleAction';
import { emptyAutoCut } from '../Redux/Action/AutoCut';
import { notifyFailed } from '../utlity/Notification';
import toast, { Toaster } from 'react-hot-toast'
const loginDetails = {
  userName:'HiveAster',
  password:'password'
}
export default function Login() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const handleSubmit = (event) => {
        event.preventDefault();
      const form = new FormData(event.currentTarget);

      let data = {
        userName:form.get('loginId'),
        password:form.get("password")
      }
      if(loginDetails.userName===data.userName && loginDetails.password===data.password){
      dispatch(loginDetailSave({userName:data.userName,password:data.password}))
      dispatch(houseDataEmpty([]))
      dispatch(emptyTodayLogs({}))
      dispatch(emptySchedule([]))
      dispatch(emptyProperty({}))
      dispatch(emptyAutoCut([]))
       navigate('/dashboard')
      }else{
        notifyFailed("Invalid Login Details")
      }
    };
  return (
    <Container style={{display:'flex',justifyContent:"center",height:"100vh",alignItems:"center"}}>
        <div style={{width:"25%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",padding:"2.5%",borderRadius:"12px"}}>
       <Toaster/>
       <div>
        <img 
          style={{height:"50px",marginBottom:"10px"}}
          src={process.env.PUBLIC_URL+"./assets/Img/Logo.png"}/>
       </div>
       <form onSubmit={handleSubmit}>

       <Form.Group as={Col}  >
          <Form.Label>Login Id</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Login ID" 
            required 
            name="loginId"
            autoComplete='loginId'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            required 
            name="password"
            autoComplete='current-password'
          />
        </Form.Group>
        <Button type='submit' className='mt-2'>login</Button>
       </form>
       
        </div>
    </Container>
  )
}
