import { Button, Form, Stack, Tab, Tabs } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { SlashCircle } from 'react-bootstrap-icons'
import './TableData.css'
import { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import moment from 'moment';
import ScheduleModal from './ScheduleModal';
import { useDispatch, useSelector } from 'react-redux';
import { SelctedSwitchMannualBlock, TurnOffAllApi, TurnOffApi, TurnOnAllApi, TurnOnApi, deviceBlock } from '../Redux/Action/PropertyAction';
import { todayLogSave, todayLogs } from '../Redux/Action/LogsAction';
import LogModel from './LogModel';
import axios from 'axios';
import OnTimeRow from './OnTimeRow';
import FetchScheduleRow from './FetchScheduleRow';
import DeviceOnLIneOffLine from './DeviceOnLIneOffLine';
import LogModalOnTime from './LogModalOnTime';
import { house } from '../Redux/Action/ActionTypes';
import ViewLogbtn from './ViewLogbtn';
import ConfirmationModel from './ConfirmationModel';
import AutoCutApi from './AutoCutApi';
import { notifyFailed, notifySuccess } from '../utlity/Notification';
import EditHouseDetails from './EditHouseDetails';
import AutoCutMap from './AutoCutMap';

function TableData({ citys, selectedCity, selectedAppliance, selectedRoom, selectedProperty }) {
  let HouseDetails = useSelector(state => state.HouseDetails)
  let PropertyDetails = useSelector(state => state.PropertyDetails)
  let User = useSelector(state => state.User)
  const schedule = useSelector(state => state.schedule)
  let [logSwitch, setLogSwitch] = useState({})
  let [productDataItem, setProductDataItem] = useState([])
  let dispatch = useDispatch(null);
  const [modalShow, setModalShow] = useState(false);
  const [logModalShow, setLogModalShow] = useState(false);
  const productDataFilterAdd = (value, checked) => {

    if (checked === true) {
      setProductDataItem([...productDataItem, value])
    } else if (checked === false) {
      let newData = productDataItem.filter((e) => {
        if (e._id !== value._id) {
          return e
        }
      })
      setProductDataItem(newData)
    }

  }
  useEffect(() => {
    if (productDataItem.length == 0) {
      let checkBox = document.getElementsByName('chk')

      for (var i = 0; i < checkBox.length; i++) {
        if (checkBox[i].type == 'checkbox')
          checkBox[i].checked = false;
      }
      let checkBoxAll = document.getElementById('AllChk')
      checkBoxAll.checked = false;
    }

  }, [productDataItem])
  useEffect(() => {
    setProductDataItem([])
    let checkBoxAll = document.getElementById('AllChk')
    checkBoxAll.checked = false;
  }, [selectedProperty])
  useState(() => {

  }, [schedule])
  const selectAllBox = (checked) => {

    let checkBox = document.getElementsByName('chk')
    for (var i = 0; i < checkBox.length; i++) {
      if (checkBox[i].type == 'checkbox')
        checkBox[i].checked = checked;
    }
    if (checked === true) {
      let switches = []
      for (let house of HouseDetails) {
        for (let room of house.rooms) {
          for (let device of room.devices) {
            for (let swit of device.switches) {
              if (selectedAppliance !== "" && swit.switchName.includes(selectedAppliance) && house.propertyName.includes(selectedProperty)) {
                let obj = { ...swit, deviceId: device.deviceId, mqt: device.isMQTTDevice, houseName: house.propertyName, roomName: room.roomName, roomId: room._id, deviceType: swit.deviceType, type: device.deviceId.substring(1, 3) == "08" ? "nova" : "sync", houseId: house.house._id }
                switches.push(obj)
              } else if (selectedAppliance === "" && house.propertyName.includes(selectedProperty)) {
                let obj = { ...swit, deviceId: device.deviceId, mqt: device.isMQTTDevice, houseName: house.propertyName, roomName: room.roomName, roomId: room._id, deviceType: swit.deviceType, type: device.deviceId.substring(1, 3) == "08" ? "nova" : "sync", houseId: house.house._id }
                switches.push(obj)
              }
            }
          }
        }
      }

      setProductDataItem(switches)
    } else {
      setProductDataItem([])
    }
  }

  const todayLogOntime = async (deviceId, switchId) => {
    return await axios.post(`https://analytics.alistetechnologies.com:443/analytic/TodayLogsByDateDevice`, {
      "deviceId": deviceId,
      "roomId": "DFS"
    }).then(function (res) {
      if (res.data.final.length !== 0) {
        let filerSwitch = res.data.final.find((d) => {
          if (d.deviceId === deviceId && d.switchId === switchId) {
            return d
          }
        })
        const durationInMilliseconds = filerSwitch.ontime;

        const seconds = Math.floor(durationInMilliseconds / 1000);
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        return formattedTime
      } else {
        return "0"
      }

    }).catch(function (err) {
      console.log(err);
      return "0"
    })
  }

  let ontime = async (deviceId, switchId) => {
    let res = await todayLogOntime(deviceId, switchId)

    return res
  }
  const checkBlockedUnBlocked = (deviceId, switchId, css) => {
    let arr = css.split("")
    if (arr.length === 0) {
      arr.push('1')
    }
    if (arr[switchId] !== "0") {
      return "UnBlocked"
    } else {
      return "Blocked"
    }
  }
  // useEffect(()=>{
  //   let Mqt=[]
  //   for(let house of HouseDetails){
  //     if(Object.keys(house).length !== 0 && house.rooms.length !== 0){
  //       for(let room of house.rooms){
  //         for(let device of room.devices){
  //           for(let swit of device.switches){
  //             Mqt.push(`${device.deviceId}$${swit.switchId}`)
  //           }
  //         }
  //       }
  //     }
  //   }
  //   console.log(Mqt,"mqt");
  // },[HouseDetails])
console.log(HouseDetails,"house");
  return (
    <div style={{ paddingTop: "15px", backgroundColor: "#ffffff" }}>
      <ScheduleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        productDataItem={productDataItem}
      />
      {/* <LogModel
        show={logModalShow}
        onHide={() => {
                      setLogModalShow(false)
                      dispatch(todayLogSave({success:true,message:"No Logs Found",final:[]}))
                    }}
        logSwitch={logSwitch}
      /> */}
      <LogModalOnTime
        show={logModalShow}
        onHide={() => {
          setLogModalShow(false)
          // dispatch(todayLogSave({success:true,message:"No Logs Found",final:[]}))
        }}
        logSwitch={logSwitch}
      />
      <div style={{ textAlign: "left", width: "fit-content", marginLeft: "30px", padding: "0.6rem", borderTopRightRadius: "10.2519px", borderTopLeftRadius: "10.2519px", backgroundColor: "#F9F9FB" }}>
        Breakdown
      </div>
      <div style={{ backgroundColor: "#F9F9FB", }}>
        <Stack direction="horizontal" gap={3} style={{ marginLeft: "60px", paddingTop: "15px", marginBottom: "15px" }}>
          <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => {
              if (productDataItem.length > 0) {
                deviceBlock(productDataItem, setProductDataItem, User, 0, house?.house?._id, dispatch, citys, selectedCity, PropertyDetails.property)
              } else {
                notifyFailed("Select Atleast One Appliance")
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Block manual switches
          </Button>
          <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => {
              if (productDataItem.length > 0) {
                deviceBlock(productDataItem, setProductDataItem, User, 1, house?.house?._id, dispatch, citys, selectedCity, PropertyDetails.property)
              } else {
                notifyFailed("Select Atleast One Appliance")
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            UnBlock manual switches
          </Button>
          {/* <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => {
              if(productDataItem.length>0){
                setModalShow(true)                
              }else{
                  notifyFailed("Select Atleast One Appliance")
                }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Schedule
          </Button> */}
          <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => {
              if (productDataItem.length !== 0) {
                TurnOffAllApi(dispatch, productDataItem, setProductDataItem)
              } else {
                notifyFailed("Select Atleast One Appliance")
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Turn Off
          </Button>
          <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => {
              if (productDataItem.length !== 0) {
                TurnOnAllApi(dispatch, productDataItem, setProductDataItem)
              } else {
                notifyFailed("Select Atleast One Appliance")
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Turn On
          </Button>
          {/* <ConfirmationModel/> */}
          {/* <CSVLink filename={`Logs_${moment(new Date()).format("DD-MMM-YYYY")}.csv`} style={{ textDecoration: "none" }} data={productDataItem}>
            <Button
              variant="outline-secondary"
              size='sm'
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <SlashCircle style={{ marginRight: "5px" }} />
              Download CSV
            </Button>
          </CSVLink> */}
          {/* <AutoCutApi  city={selectedCity} productDataItem={productDataItem}/> */}
        </Stack>
        <Table hover style={{ textAlign: "left", marginLeft: "30px", width: "96%", borderRadius: "12px", overflow: "hidden" }}>

          <thead>
            <tr >
              <th> <Form.Check aria-label="option 1" name="AllChk" id="AllChk" onChange={(event) => selectAllBox(event.target.checked)} /></th>
              {/* <th className='tableHeading'>#</th> */}
              <th className='tableHeading'>Property</th>
              {/* <th className='tableHeading'>City</th> */}
              {/* <th>Mqt</th> */}
              <th className='tableHeading'>Area</th>
              <th className='tableHeading'>Name</th>
              {/* <th className='tableHeading'>On Time</th> */}
              {/* <th className='tableHeading'>Device Status</th> */}
              {/* <th className='tableHeading'>Auto Cut Time</th> */}
              {/* <th className='tableHeading'>Schedule</th> */}
              <th className='tableHeading'>Current Status</th>
              <th className='tableHeading'>Connection Status</th>
              <th className='tableHeading'>Connection Strength</th>
              <th className='tableHeading'>Manual Switch Status</th>
              <th className='tableHeading'>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              HouseDetails.length !== 0 ?
                HouseDetails.map((house, ho) => {
                  console.log(house,citys,"ddddd1");
                  if (Object.keys(house).length !== 0 && house.rooms.length !== 0) {
                    return (
                      house.rooms.sort((a,b)=>a.roomName-b.roomName).map((room, roi) => {

                        return room.devices.map((device, di) => {
                        
                          return (
                            device.switches.map((switchs, index) => {
                              if (house.city.includes(selectedCity) && switchs.switchName.includes(selectedAppliance) && room.roomName.includes(selectedRoom) && switchs.deviceType !== 7 && house.propertyName.includes(selectedProperty)) {
                                return (
                                  <tr key={switchs._id}>
                                    <td><Form.Check aria-label="option 1" onChange={(event) => productDataFilterAdd({ ...switchs, deviceId: device.deviceId, mqt: device.isMQTTDevice, houseName: house.propertyName, roomName: room.roomName, roomId: room._id, deviceType: switchs.deviceType, type: device.deviceId.substring(1, 3) == "08" ? "nova" : "sync", houseId: house.house._id }, event.target.checked)} name='chk' /></td>
                                    {/* <td className='tableData'>`{device.deviceId} - {switchs.switchId} - {device.css}</td>  */}
                                    <td className='tableData'>{house.propertyName}</td>
                                    {/* <td className='tableData'>{house.city}</td> */}
                                    {/* <td>{device.isMQTTDevice===true?"yes":"no"}</td> */}
                                    <td className='tableData'>{room.roomName}</td>
                                    <td className='tableData'>{switchs.switchName}</td>
                                    {/* <OnTimeRow deviceId={device.deviceId} switchId={switchs.switchId}/> */}
                                    {/* <td className='tableData'><AutoCutMap deviceId={device.deviceId} switchId={switchs.switchId}/></td> */}
                                    {/* <td className='tableData'><FetchScheduleRow deviceId={device.deviceId} switchId={switchs.switchId} roomName={room.roomName} propertyName={house.propertyName} switchName={switchs.switchName}/></td> */}
                                    <td>{switchs.switchState !== "0" ? (
                                    <Button 
                                       variant="outline-success" 
                                       size='sm' 
                                       style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}
                                       onClick={() => TurnOffApi(dispatch, switchs, device.deviceId, house.house._id, device.isMQTTDevice)}

                                    >On</Button> 
                                    ): <Button 
                                        size='sm' 
                                        style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} 
                                        variant="outline-danger"
                                        onClick={() => TurnOnApi(dispatch, switchs, device.deviceId, house.house._id, device.isMQTTDevice)}
                                      >Off</Button>}</td>
                                    <td><DeviceOnLIneOffLine deviceId={device.deviceId} mqt={device.isMQTTDevice} /></td>
                                    <td className='tableData'>{device.strength}</td>
                                    <td className='tableData'>{checkBlockedUnBlocked(device.deviceId, switchs.switchId, device.ess)}</td>
                                    <td>
                                      <Stack direction="horizontal" gap={3}>
                                        {/* {
                                          switchs.switchState !== "0" ?
                                            <Button variant="outline-secondary" size='sm' style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                              onClick={() => TurnOnApi(dispatch, switchs, device.deviceId, house.house._id, device.isMQTTDevice)}
                                            ><SlashCircle style={{ marginRight: "5px" }} />Turn Off</Button>
                                            :
                                            <Button variant="outline-secondary" size='sm' style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                              onClick={() => TurnOffApi(dispatch, switchs, device.deviceId, house.house._id, device.isMQTTDevice)}
                                            ><SlashCircle style={{ marginRight: "5px" }} />Turn On</Button>
                                        } */}
                                        <Button variant="outline-secondary" size='sm' style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                          onClick={() => {
                                            setLogSwitch({ ...switchs, deviceId: device.deviceId, mqt: device.isMQTTDevice, houseName: house.propertyName, roomName: room.roomName, roomId: room._id, deviceType: switchs.deviceType, type: device.deviceId.substring(1, 3) == "08" ? "nova" : "sync" })
                                            setLogModalShow(true)
                                          }}
                                        ><SlashCircle style={{ marginRight: "5px" }} />View Logs</Button>
                                        {/* <EditHouseDetails data={{...switchs,deviceId:device.deviceId,mqt:device.isMQTTDevice,houseName:house.propertyName,houseId:house.house._id,roomName:room.roomName,roomId:room._id,deviceType:switchs.deviceType,type:device.deviceId.substring(1,3)=="08"?"nova":"sync",city:house.city}} city={citys}/> */}
                                      </Stack>
                                    </td>

                                  </tr>

                                )
                              }
                            })
                          )
                        })

                      }))
                  }

                }) : null

            }


          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default TableData;